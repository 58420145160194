import { useParams } from 'react-router-dom'
import { get } from 'services/auctions'
import { can, useAuth } from 'components/auth/AuthContext'
import { useEffect, useState } from 'react'
import AuctionLayout from 'views/admin/auctions/components/AuctionLayout'
import Button from 'components/buttons/Button'
import {
    FaFileExcel,
    FaFilePdf
} from 'react-icons/fa6'
import {
    IoOptions
} from 'react-icons/io5'
import ReportOptionsModal from './components/modals/ReportOptionsModal'

const Reports = () => {
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ showOptionsModal, setShowOptionsModal ] = useState(false)
    const [ activeReport, setActiveReport ] = useState(false)

    const auth = useAuth()
    const canViewAllReports = can(auth, 'view_auctions_reports_all')

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
        })
    }, [])

    const showOptions = (report) => {
        setActiveReport(report)
        setShowOptionsModal(true)
    }

    const showFormat = (report, format) => {
        return report.formats.indexOf(format) >= 0
    }

    const showFormatButton = (report, format, options) => {
        if (! report || ! showFormat(report, format)) {
            return
        }

        let href = `${process.env.REACT_APP_BASE_URL}/admin/report/${auction.id}?report=${report.type}&format=${format}`

        if (options) {
            const params = new URLSearchParams(options)
            href += '&' + params.toString()
        }

        if (format === 'excel') {
            return (
                <Button target="_blank" href={href} color="outline" size="md">
                    <FaFileExcel className="w-5 h-5 text-green-600" /> Excel
                </Button>
            )
        } else if (format === 'pdf') {
            return (
                <Button target="_blank" href={href} color="outline" size="md">
                    <FaFilePdf className="w-5 h-5 text-red-600" /> PDF
                </Button>
            )
        }
    }

    const reports = [
        canViewAllReports ? {
            name: "Auction Summary",
            type: "auction-summary",
            formats: ['pdf', 'excel']
        } : null,
        {
            name: "Auction Catalog",
            type: "auction-catalog",
            formats: ['pdf']
        },
        {
            name: "Auctioneer Report",
            type: "auctioneer-report",
            formats: ['pdf']
        },
        {
            name: "Lot Summary",
            type: "lot-summary",
            formats: ['pdf', 'excel']
        },
        canViewAllReports ? {
            name: "Buyer Summary",
            type: "buyer-summary",
            formats: ['pdf', 'excel']
        } : null,
        {
            name: "Consignor Summary",
            type: "consignor-summary",
            formats: ['pdf', 'excel']
        },
        canViewAllReports ? {
            name: "Bidder Report",
            type: "bidder-report",
            formats: ['pdf', 'excel']
        } : null,
        {
            name: "Invoice Payments Report",
            type: "invoice-payments-report",
            formats: ['pdf', 'excel']
        },
        {
            name: "Auction Lot Sales Report",
            type: "auction-lot-sales-report",
            formats: ['pdf', 'excel'],
            options: [
                {
                    label: 'Premium Included in Price',
                    name: 'premium_included'
                }
            ]
        },
    ].filter(report => report !== null)

    if (! auction) {
        return
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
            <div>
                {reports.map((report) => {
                    return (
                        <div className="flex gap-2 items-center my-4">
                            <div className="w-60">{report.name}</div>
                            <div>
                                {showFormatButton(report, 'pdf')}
                            </div>
                            <div>
                                {showFormatButton(report, 'excel')}
                            </div>
                            <div>
                                {report.options && (
                                    <Button onClick={() => showOptions(report)} color="outline" size="md">
                                        <IoOptions className="w-5 h-5" /> Options
                                    </Button>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>

            <ReportOptionsModal
                auction={auction}
                report={activeReport}
                visible={showOptionsModal}
                setVisible={setShowOptionsModal}
                showFormatButton={showFormatButton}
            />
        </AuctionLayout>
    )
}

export default Reports